import React, { useState } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';
import Logo from "../../Assets/logo.png";
import '../../Assets/Styles.css';

const Header = () => {
    const [expanded, setExpanded] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const history = useHistory();

    const handleBigNinjaClick = () => {
        history.push('/pecanprep#bidninja');
    };

    const handlePecanPrepClick = () => {
        history.push('/pecanprep');
    };

    

    return (
        <Navbar bg="light" expand="lg" expanded={expanded} style={styles.navbar}>
            <LinkContainer to="/">
                <Navbar.Brand style={styles.logoContainer}>
                    <img src={Logo} alt="Logo" style={styles.logo} />
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="" />
            <Navbar.Collapse>
                <Nav className="navtext">
                    <LinkContainer to="/home" onClick={() => setExpanded(false)}>
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>

                    {/* Dropdown Wrapper */}
                    <div
                        style={styles.dropdownWrapper}
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                    >
                        <LinkContainer to="/pecanprep" onClick={() => setExpanded(false)}>
                        <Nav.Link style={styles.navLink} >Products
                        {showDropdown && (
                            <div style={styles.dropdownMenu}>
                                <div
                                    style={{
                                        ...styles.dropdownItem,
                                        // textDecoration: "underline",
                                        // textDecorationColor: hoveredItem === "pecanprep" ? "black" : "transparent",
                                        backgroundColor: hoveredItem === "pecanprep" ? "rgba(253, 126, 20, 0.1)" : 'rgba(253, 126, 20, 0.6)',
                                        // color: hoveredItem === "pecanprep" ? "rgba(12, 0, 0, 0.75)" : "none",
                                    }}
                                    onMouseEnter={() => setHoveredItem("pecanprep")}
                                    onMouseLeave={() => setHoveredItem(null)}
                                    onClick={handlePecanPrepClick}
                                >
                                    PecanPrep
                                </div>
                                {/* <div style={styles.dropdownDivider}></div> */}
                                <LinkContainer to="/pecanprep#bidninja" onClick={() => setExpanded(false)}>
                                <div style={styles.dropdownMenu}>
                                <div
                                    style={{
                                        ...styles.dropdownItem,
                                        // textDecoration: "underline",
                                        // textDecorationColor: hoveredItem === "bidninja" ? "black" : "transparent",
                                        backgroundColor: hoveredItem === "bidninja" ? "rgba(253, 125, 20, 0.1)" : "rgba(253, 126, 20, 0.6)",
                                        // color: hoveredItem === "bidninja" ? "rgba(12, 0, 0, 0.75)" : "none",
                                    }}
                                    onMouseEnter={() => setHoveredItem("bidninja")}
                                    onMouseLeave={() => setHoveredItem(null)}
                                    onClick={handleBigNinjaClick}
                                >
                                    BidNinja
                                </div>
                                </div>
                                </LinkContainer>
                            </div>
                        )}
                        </Nav.Link>
                        </LinkContainer>
                    </div>
                    <div style={styles.partitionLine}></div>

                    <LinkContainer to="/service" onClick={() => setExpanded(false)}>
                        <Nav.Link>Services</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/technology" onClick={() => setExpanded(false)}>
                        <Nav.Link>Technology</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/case-studies" onClick={() => setExpanded(false)}>
                        <Nav.Link>Case Studies</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/team" onClick={() => setExpanded(false)}>
                        <Nav.Link>Team</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/about" onClick={() => setExpanded(false)}>
                        <Nav.Link>
                            <Button style={styles.button}>Get in Touch</Button>
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

// Styles
const styles = {
    navbar: {
        padding: "10px 20px",
        borderBottom: "2px solid #ccc",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
    },
    logo: {
        height: "60px",
        width: "auto",
    },
    dropdownWrapper: {
        position: "relative",
        display: "inline-block",
        padding: "10px 15px",
    },
    navLink: {
        cursor: "pointer",
        color: "#000",
        textDecoration: "none",
    },
    dropdownMenu: {
        position: "absolute",
        backgroundColor: "rgba(247, 114, 6, 0.6)" ,
        transpircy: "0.2",
        left: "0",
        top: "100%",
        borderRadius: "4px",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        width: "120px",
        zIndex: 1000,
        border: "2px solid #b1acac",
       
    },
    dropdownItem: {
        padding: "3px",
        paddingLeft: "30px",
        textAlign: "center",
        fontSize: "14px",
        textAlign: "left",
        cursor: "pointer",
        color: "rgba(12, 0, 0, 0.75)",
        // textDecoration: 'underline',
        transition: "background 0.2s",
    },
    dropdownDivider: {
        height: "1px",
        backgroundColor: "#b1acac",
    },
    partitionLine: {
        width: "2px",
        height: "25px",
        backgroundColor: "#000",
        margin: "10px 5px 10px 3px",
        display: "inline-block",
        verticalAlign: "middle",
    },
    button: {
        borderRadius: "6px",
        padding: "10px 24px",
    },
};

export default Header;
